export function reportEvent({ category, action, label, ...rest }) {
  window.ga('send', {
    hitType: 'event',
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    ...rest,
  })
}

export function reportException(message, { isFatal = false } = {}) {
  window.ga('send', {
    hitType: 'exception',
    exDescription: message,
    exFatal: isFatal,
  })
}

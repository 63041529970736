import { onLoad } from '../utils/events'
import smoothscroll from 'smoothscroll-polyfill'

onLoad(setupHomepageSamePageLinks)

function setupHomepageSamePageLinks() {
  if (isHomepage()) {
    smoothscroll.polyfill()
    setupBackToTopLinks()
    setupSamePageLinks()
  }
}

function isHomepage() {
  return ['', '/', '/index.html'].includes(location.pathname)
}

function setupBackToTopLinks() {
  const homeLinks = document.querySelectorAll('[href="/"]')
  for (const link of homeLinks) {
    link.addEventListener('click', (e) => {
      e.preventDefault()
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    })
  }
}

function setupSamePageLinks() {
  const links = document.querySelectorAll('[href^="/#"]')
  for (const link of links) {
    const { hash } = new URL(link.href)
    link.addEventListener('click', (e) => {
      e.preventDefault()
      document.querySelector(hash).scrollIntoView({
        behavior: 'smooth',
      })
    })
  }
}

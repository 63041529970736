import 'intersection-observer'

export function waitForIntersection(element, options) {
  return new Promise((resolve) => {
    const observer = new IntersectionObserver(resolveOnIntersection, options)
    observer.observe(element)

    function resolveOnIntersection(intersectionChanges, intersectionObserver) {
      for (const change of intersectionChanges) {
        if (change.intersectionRatio > 0) {
          intersectionObserver.disconnect()
          resolve()
          return
        }
      }
    }
  })
}

export function onLoad(callback) {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', callback)
  } else {
    callback()
  }
}

export function onClickInsideFrame(frame, callback) {
  const state = { isMouseOver: false }
  frame.addEventListener('mouseover', () => (state.isMouseOver = true))
  frame.addEventListener('mouseout', () => (state.isMouseOver = false))
  addEventListener('blur', () => state.isMouseOver && callback())
}

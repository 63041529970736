import { reportEvent, reportException } from '../utils/analytics'
import { waitForIntersection } from '../utils/intersection'
import { onClickInsideFrame, onLoad } from '../utils/events'

setupErrorAnalytics()
onLoad(() => {
  setupMapAnalytics()
  setupSectionAnalytics()
  setupContactDetailAnalytics()
})

function setupErrorAnalytics() {
  addEventListener('error', (error) => reportException(error.message))
}

function setupSectionAnalytics() {
  let sectionInteractionCount = 0
  const sections = document.querySelectorAll('[data-section]')
  for (const section of sections) {
    waitForIntersection(section, { threshold: 0.25 }).then(() =>
      reportEvent({
        category: 'PageSections',
        action: 'view',
        label: section.dataset.section,
        nonInteraction: 0 === sectionInteractionCount++,
      })
    )
  }
}

function setupMapAnalytics() {
  const map = document.querySelector('[data-map]')
  if (map) {
    onClickInsideFrame(map, () =>
      reportEvent({
        category: 'ContactDetails',
        action: 'click',
        label: 'Map',
      })
    )
  }
}

function setupContactDetailAnalytics() {
  const contactDetails = document.querySelectorAll('[data-contact-detail]')
  for (const contactDetail of contactDetails) {
    contactDetail.addEventListener('click', () =>
      reportContactDetailInteraction(contactDetail, 'click')
    )
    contactDetail.addEventListener('copy', () =>
      reportContactDetailInteraction(contactDetail, 'copy')
    )
  }
}

function reportContactDetailInteraction(contactDetail, action) {
  reportEvent({
    category: 'ContactDetails',
    action,
    label: contactDetail.dataset.contactDetail,
  })
}

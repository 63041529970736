import 'scrolling-element'
import { onLoad } from '../utils/events'

onLoad(setupHeaderScrollListener)

function setupHeaderScrollListener() {
  const header = document.querySelector('[data-header]')

  markHeaderIfPageScrolled()
  addEventListener('scroll', markHeaderIfPageScrolled)

  function markHeaderIfPageScrolled() {
    if (document.scrollingElement.scrollTop > 0) {
      header.classList.add('Header--scrolled')
    } else {
      header.classList.remove('Header--scrolled')
    }
  }
}

import isEmail from 'isemail'
import { onLoad } from '../utils/events'
import { reportEvent, reportException } from '../utils/analytics'

const SELECTORS = {
  FORM: '[data-form]',
  FORM_FOOTER: '[data-form-footer]',
  INPUT_ERROR: '[data-input-error]',
}
const CLASSNAMES = {
  FORM: {
    IS_SUBMITTING: 'Form--submitting',
    HAS_GENERIC_ERROR: 'Form--error',
    SUBMIT_WAS_SUCCESSFUL: 'Form--success',
  },
  INPUT: {
    HAS_ERROR: 'Form__input--error',
  },
}
const MESSAGES = {
  SUCCESS: 'Thank you! We will get back to you as soon as possible.',
  ERROR: {
    generic: `Sorry, there was a problem submitting the form. Please, try submitting it again soon, or <a href="mailto:${process.env.CONTACT_EMAIL}">sending it via email</a>, if you prefer.`,
    email_is_invalid: 'Please, enter a valid email address',
    email_is_empty: 'Please, enter your email',
    name_is_empty: 'Please, enter your name',
    message_is_empty: "Please, enter the message you'd like to send",
    no_contact: 'Please, enter a contact email or number.',
  },
}

onLoad(setupFormHandlersAndAnalytics)

function setupFormHandlersAndAnalytics() {
  const forms = document.querySelectorAll(SELECTORS.FORM)
  for (const form of forms) {
    form.addEventListener('focusin', reportFocus)
    form.addEventListener('change', reportChange)
    form.addEventListener('submit', handleSubmit)

    // eslint-disable-next-line no-inner-declarations
    function reportFocus() {
      form.removeEventListener('focusin', reportFocus)
      report(form, 'focus')
    }
    // eslint-disable-next-line no-inner-declarations
    function reportChange() {
      form.removeEventListener('change', reportChange)
      report(form, 'input')
    }
  }
}

function handleSubmit(event) {
  event.preventDefault()
  submitForm(event.target)
}

async function submitForm(form) {
  if (form.dataset.formSubmitting) {
    return
  }

  report(form, 'submit')

  form.dataset.formSubmitting = true
  const spinnerTimeout = setTimeout(
    () => form.classList.add(CLASSNAMES.FORM.IS_SUBMITTING),
    400
  )

  try {
    clearFormMessages(form)
    const errors = validate(form)
    if (errors.length > 0) {
      renderFormErrors(form, errors)
      report(form, 'error:invalid')
      reportException(reduceErrorsToException(errors))
      return
    }
    await submit(form)
    report(form, 'success')
    renderFormSuccess(form)
    form.reset()
  } catch (error) {
    renderFormError(form, 'generic')
    report(form, 'error:generic')
    reportException(error.message)
  } finally {
    clearTimeout(spinnerTimeout)
    delete form.dataset.formSubmitting
    form.classList.remove(CLASSNAMES.FORM.IS_SUBMITTING)
  }
}

function clearFormMessages(form) {
  form.classList.remove(CLASSNAMES.FORM.IS_SUBMITTING)
  form.classList.remove(CLASSNAMES.FORM.HAS_GENERIC_ERROR)
  form.classList.remove(CLASSNAMES.FORM.SUBMIT_WAS_SUCCESSFUL)
  form
    .querySelectorAll(`.${CLASSNAMES.INPUT.HAS_ERROR}`)
    .forEach((input) => input.classList.remove(CLASSNAMES.INPUT.HAS_ERROR))
  form
    .querySelectorAll(`${SELECTORS.FORM_FOOTER},${SELECTORS.INPUT_ERROR}`)
    .forEach((container) => (container.innerHTML = ''))
}

function validate(form) {
  const type = form.dataset.form
  const formData = new FormData(form)
  switch (type) {
    case 'contact':
      return validateContactRequest(formData)
    case 'consultation':
      return validateConsultationRequest(formData)
    default:
      return [{ error: 'generic' }]
  }
}
function validateContactRequest(formData) {
  const errors = []
  const name = formData.get('name')
  const email = formData.get('email')
  const message = formData.get('message')
  if (!isNonEmptyString(name)) errors.push({ input: 'name', error: 'is_empty' })
  if (!isNonEmptyString(email))
    errors.push({ input: 'email', error: 'is_empty' })
  else if (!isValidEmail(email))
    errors.push({ input: 'email', error: 'is_invalid' })
  if (!isNonEmptyString(message))
    errors.push({ input: 'message', error: 'is_empty' })
  return errors
}
function validateConsultationRequest(formData) {
  const errors = []
  const name = formData.get('name')
  const email = formData.get('email')
  const number = formData.get('number')
  if (!isNonEmptyString(name)) errors.push({ input: 'name', error: 'is_empty' })
  if (isNonEmptyString(email) && !isValidEmail(email))
    errors.push({ input: 'email', error: 'is_invalid' })
  if (!isNonEmptyString(email) && !isNonEmptyString(number))
    errors.push({ error: 'no_contact' })
  return errors
}
function isValidEmail(value) {
  return isEmail.validate(value)
}
function isNonEmptyString(value) {
  return Boolean(typeof value === 'string' && value.trim())
}

function renderFormErrors(form, errors) {
  errors.forEach(({ input, error }) =>
    input
      ? renderInputError(getInputNode(form, input), `${input}_${error}`)
      : renderFormError(form, error)
  )
}

function getInputNode(form, inputName) {
  return form.elements[inputName].parentNode
}

function renderInputError(input, error) {
  input.classList.add(CLASSNAMES.INPUT.HAS_ERROR)
  input
    .querySelector(SELECTORS.INPUT_ERROR)
    .appendChild(createErrorElement(error))
}

function renderFormSuccess(form) {
  renderFormMessage(form, {
    modifier: CLASSNAMES.FORM.SUBMIT_WAS_SUCCESSFUL,
    message: createParagraph(MESSAGES.SUCCESS),
  })
}

function renderFormError(form, error) {
  renderFormMessage(form, {
    modifier: CLASSNAMES.FORM.HAS_GENERIC_ERROR,
    message: createErrorElement(error),
  })
}

function renderFormMessage(form, { modifier, message }) {
  form.classList.add(modifier)
  form.querySelector(SELECTORS.FORM_FOOTER).appendChild(message)
}

function createErrorElement(error) {
  return createParagraph(MESSAGES.ERROR[error])
}

function createParagraph(content) {
  const paragraph = document.createElement('p')
  paragraph.innerHTML = content
  return paragraph
}

async function submit(form) {
  const formData = new FormData(form)
  const response = await fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams(formData).toString(),
  })
  if (!response.ok) {
    throw new Error('Form submission response not ok')
  }
  return response
}

function report(form, action) {
  reportEvent({
    category: 'Forms',
    label: form.getAttribute('name'),
    action,
  })
}

function reduceErrorsToException(errors) {
  return errors
    .map(({ input, error }) => (input ? `${input}_${error}` : error))
    .join('|')
}

import '@babel/polyfill'
import './components/header'
import './components/form'
import './components/smooth-scroll'
import './components/analytics'

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () =>
    navigator.serviceWorker.register('../service-worker.js')
  )
}
